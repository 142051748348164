import React, { useContext, useMemo } from "react";
import Context from "../context/Context";
import "../Styles/FigmaStyle.css";
import { GetPlayerIcon, GetPlayerBackground } from "../Sprites/PlayerIconLoader.js";


export function PageBackground(props){
    const {bkgColor, bkgImage, useBkgGradient, useBlur, Logo, useLogo, useSmallLogo, bkgStyle, isLandscape } = props;
    
    const backgroundImage = useMemo(() => { return  bkgImage ?? ''}, [bkgImage])
    const pageLogo = useMemo(() => { return  Logo}, [Logo])

    const bkgBackboard = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: bkgColor ?? 'black',

    }
    
    const bkgBlurOverlay = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: 'black',
        filter: useBlur ? 'blur(.375rem)' : ''   
    }

    const bkg = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundRepeat: 'no-repeat',
        backgroundSize: isLandscape ? '100% 225%' :'100% auto',
        backgroundColor: bkgColor ?? 'white'
    }

    const bkgGradientRepeat = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        background: useBkgGradient ? "repeating-linear-gradient(40deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .75), rgba(0, 0, 0, 0), rgba(0, 0, 0, .75))" : ''
    }

    const bkgGradientLinear = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        background: useBkgGradient ? "linear-gradient(to right, rgba(0, 0, 0, .1) 25%, rgba(0,0,0,0))" : ''
    }

    const CenteredLogo = {
        position: "inherit",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        background: `center no-repeat url(${pageLogo})`, 
        filter: "drop-shadow(12px 12px 8px black)"
    }

    const SmallLogo = {
        position: "inherit",
        top: "7.5%",
        height: "25%",
        width: "100%",
        background: `center/50% no-repeat url(${pageLogo})`, 
        filter: "drop-shadow(6px 6px 4px black)",
    }

    const BlackOverlay = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: 'rgba(25,25, 25, .825)',
    }

    return(
        <div style={{...bkgBackboard}}>
            <div style={{...bkgBlurOverlay}}>
                <div id="PageBackground" style={{background: `url(${backgroundImage}) center`, ...bkg, ...bkgStyle }} />
                <div id="PageGradient1" style={{...bkgGradientRepeat }}>          
                    <div id="PageGradient2" style={{...bkgGradientLinear }}>
                        {
                            useLogo ?
                            (!useSmallLogo ? <div id="PageLogo" style={{...CenteredLogo }} /> : <div id="PageLogo" style={{...SmallLogo }} />) : <></>
                        }
                    </div>
                    {useBlur ? <div style={{...BlackOverlay}}/> : <></>}
                </div>
            </div>
        </div>
    );
}

export function PageLanding(props){
    const {bkgImage, Logo, Latency, Disconnected} = props

    return(
        <>
        <PageBackground bkgColor={'black'} bkgImage={bkgImage} useLogo Logo={Logo} useBlur={Disconnected} />
        <PlayerBanner myColor={'orange'} Latency={Latency}/>
         {Disconnected ? <ReconnectionPrompt /> : <></>}
        </>
    );
}

export function PlayerBanner(props){
    const {Latency} = props;

    const bkg = {
        position: "fixed",
        top: "0",
        left: "0",
        height: "15%",
        width: "100%",
        backgroundColor: "black",
        boxShadow: "0px 4px 48px 6px rgba(0,0,0, .75)"
    
    }

    const banner = {
        position: "absolute",
        display: "flex",
        flexFlow: "row no-wrap",
        gap: "4px",
        justifyContent: "flex-start",
        bottom: "5%",
        left: "6px",
        height: "50%",
        width: "100%",
    }

    return(
        <div id="PlayerBannerBackground" style={{...bkg }}>
            <div id="PlayerBanner" style={{...banner }}>
                <PlayerIconBanner size={56} borderSize={2}  />
                <PlayerNameBanner size={"75%"} textAlign={"left"}/>
                <ConnectionStatus size={56} latency={Latency ?? null}/>             
            </div>
            

        </div>
    );
}

function PlayerIconBanner(props)
{
    const {size} = props;
    const context = useContext(Context);

    const bkg = {
        position: "relative",
        display: 'flex',
        height: `${size}px`,
        width: `${size}px`,
        borderRadius: "4px",
        boxShadow: "0px 2px 2px 2px rgba(0,0,0, .25)",
        margin: "0 auto",
        outline: '1px outset gray'
    }

    const pColor = {
        position: "absolute",
        top: '0',
        left: '0',
        height: '100%',
        borderRadius: '4px',
        aspectRatio: "1/1", 
        backgroundSize: "100% auto",   
        
    }

    const pIcon = {
        position: "relative",
        height: '100%',
        aspectRatio: "1/1", 
        backgroundSize: "100% auto", 
        filter: "drop-shadow(4px 4px 4px black)"   
    }
    

    const bkgImg = useMemo(() => { return GetPlayerBackground(context.user.playerColor)}, [context.user.playerColor]);
    const iconImg = useMemo(() => { return GetPlayerIcon(context.user.playerIcon)}, [context.user.playerIcon]);

    return(
        <div id="PlayerBannerIconBox" style={{...bkg}}>
            <img id="PlayerColor" src={bkgImg} style={{...pColor}}></img>
            {iconImg != null ? <img id="PlayerIcon" src={iconImg ?? ''} style={{...pIcon}} /> : <></>}
        </div>
    );
}

 function PlayerNameBanner(props)
{
    const {size, textAlign} = props;
    const context = useContext(Context);

    const bkg = {
        position: "relative",
        height: "100%",
        width: `${size}`,
        display: 'flex',
        justifyContent: 'center',
    }

    const pName = {
        position: 'relative',
        width: '100%',
        margin: "auto auto",
        textAlign: `${textAlign}`,
        color: 'white',
        fontSize: 'clamp(24pt, 10vw, 36pt)',
        fontWeight: '600',
        overflow: "hidden",
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        fontFamily: 'Rajdhani'
    }

    const playerName = useMemo(() => { return  context.user.playerName ?? 'Rookie'}, [context.user.playerName])

    return(
        <div id="PlayerNameBox" style={{...bkg }}>
            <span id="PlayerName" style={{...pName}} >{playerName}</span>
        </div>
    );
}

function ConnectionStatus(props){
    const {size, latency} = props;
    
    const signal = useMemo(() => {
    let s = 0;
    if(latency <= 500) s++;
    if(latency <= 250) s++;
    if(latency <= 100) s++;
    return s}, [latency])

    const b1Color = useMemo(() => {return {
        backgroundColor: (signal > 0) ? '#47db34' : 'rgba(12, 56, 6, .625)',
        outline: (signal > 0) ? '1px solid rgba(71, 219, 52, .5)' : '1px solid rgba(71, 219, 52, .25)',   
    }}, [latency, signal]);

    const b2Color = useMemo(() => {return {
        backgroundColor: (signal > 1) ? '#47db34' : 'rgba(12, 56, 6, .625)',
        outline: (signal > 1) ? '1px solid rgba(71, 219, 52, .5)' : '1px solid rgba(71, 219, 52, .25)',   
    }}, [latency, signal]);

    const b3Color = useMemo(() => {return {
        backgroundColor: (signal > 2) ? '#47db34' : 'rgba(12, 56, 6, .625)',
        outline: (signal > 2) ? '1px solid rgba(71, 219, 52, .5)' : '1px solid rgba(71, 219, 52, .25)',  
    }}, [latency, signal]);

    const bkg = {
        position: "absolute",
        display: 'flex',
        flexFlow: 'column noWrap',
        right: '24px',
        bottom: '5%',

        height: `${size}px`,
        width: `${size}px`,
    }

    const BarsContainer = {
        position: "relative",
        display: 'flex',
        flexFlow: 'row noWrap',
        justifyContent: 'center',

        gap: '3px',
        margin: 'auto auto',

        height: '60%',
        width: '100%',
    }

    const TextContainer = {
        position: "relative",
        display: 'flex',
        margin: 'auto auto',

        height: '40%',
        width: '100%',
    }

    const ConnectionBar = {
        position: 'relative',
        display: 'flex',

        borderRadius: '2px 2px 0 0',
        boxShadow: "0px 2px 2px 2px rgba(71, 219, 52, 0.125)",
        width: '10px',

    }

    const tStyle = {
        overflow: 'visible',
        textWrap: 'nowrap',
        whiteSpace: 'show',
        color: 'white'
    }

    return(
        <div style={{...bkg}}>
            <div style={{...BarsContainer}}>
                <div style={{...ConnectionBar, ...b1Color, height: '18px', top: '12px'}} />
                <div style={{...ConnectionBar, ...b2Color, height: '24px', top: '6px'}} />
                <div style={{...ConnectionBar, ...b3Color, height: '30px', top: '0'}} />
            </div>
            <div style={{...TextContainer}}>
                <div style={{display: 'flex', margin: '0 auto'}}>
                    <TextBox text={`${latency ? latency + " ms" : '---'}`} fontSize={'16pt'} textAlign={'right'} textStyle={{...tStyle}} />
                </div>
                
            </div>

        </div>
    );
}

export function PlayerIcon(props)
{
    const context = useContext(Context);
    const {size} = props;

    const bkg = {
        position: 'absolute',
        top: '0',
        left: '0',
        height: size ?? '100%',
        width: size ?? '100%',
        borderRadius: "4px",
    }

    const pColor = {
        position: "absolute",
        top: '0',
        left: '0',
        height: '100%',
        aspectRatio: "1/1", 
        backgroundSize: "100% auto",
        filter: "drop-shadow(4px 4px 4px rgba(0,0,0, .5))"  
        
    }

    const pIcon = {
        position: "relative",
        height: '100%',
        aspectRatio: "1/1", 
        backgroundSize: "100% auto", 
        filter: "drop-shadow(4px 4px 4px black)"   
    }

    const bkgImg = useMemo(() => { return GetPlayerBackground(context.user.playerColor)}, [context.user.playerColor]);
    const iconImg = useMemo(() => { return GetPlayerIcon(context.user.playerIcon)}, [context.user.playerIcon]);

    return(
        <div id="PlayerIconBox" style={{...bkg }}>
            <img id="PlayerColor" src={bkgImg} style={{...pColor}}></img>
            {iconImg != null ? <img id="PlayerIcon" src={iconImg ?? ''} style={{...pIcon}} /> : <></>}
        </div>
    );
}

 export function TextBox(props)
{
    const {text, width, textAlign, fontSize, bkgStyle, textStyle, textWrap, selected} = props;

    const bStyle = {
        display: 'flex',
        position: 'relative',
        height: "100%",
        width: `${width}`,
        ...bkgStyle
    }

    const tStyle = {
        position: 'relative',
        width: '100%',
        margin: "auto auto",
        textAlign: textAlign ?? 'left',
        lineHeight: '100%',
        color: 'white',
        fontSize: fontSize ?? 'xx-large',
        FontWeight: '600',
        overflow: !textWrap ? "hidden" : 'wrap',
        textOverflow: 'ellipsis',
        whiteSpace: !textWrap ? "nowrap" : 'wrap',
        fontFamily: 'Rajdhani',

        pointerEvents: 'none', 
        userSelect: 'none',
        WebkitUserSelect: 'none', 
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
        
        ...textStyle
    }

    return(
        <div id="TextBox" style={{...bStyle}}>
            <span id="TextBoxText" style={{...tStyle}} >{text}</span>
        </div>
    );
}

export function ReconnectionPrompt(props){

    const overlayBackgroundArea = {
        position:'absolute',
        width: "100%",
        height: "100%", 
        top: "0",   
        zIndex: '20',
    }

    const overlayBox = {
        position: 'relative', 
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        width: '90%', 
        height: '400px', 
        margin: '50% auto',
        gap: '12px'
    }

    const overlayTextArea = {
        position: 'relative',
        display: 'flex',
        margin: '32px auto',
        width: '100%',
        height: '48px',
    }

    const buttonStyle = {
        width: '100%',
        height: '100%',
        background: `linear-gradient(to bottom, ${'#F3DD16'} 37.5%, ${'#F4B000'})`,
        border: 'none',
        boxShadow: "0px 2px 2px 2px rgba(0,0,0, .375)" ,
        borderRadius: '8px',

        fontSize: 'x-large',
        fontWeight: '700',
        fontFamily: 'Rajdhani-Bold',
        textShadow: '2px 2px 4px rgba(0,0,0, 0.1)',
        color: '#462A01'

    }

    function handleButtonClick(){
        console.log("PUSHED RECONNECT!")
        window.location.reload();
    }


    return(
        <>
        <div style={{...overlayBackgroundArea}}>
            <div style={{...overlayBox}}>
                <div style={{...overlayTextArea}}>
                    <TextBox width={'100%'} fontSize={"xxx-large"} textAlign={"center"} textWrap={true} text={"LOST CONNECTION"} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600'}} />
                </div>
                <div style={{width: '85%', height: '72px', margin: '24px auto'}}>
                    <button onClick={handleButtonClick}
                        style={{...buttonStyle}}>
                    RECONNECT</button>
                </div>                  
            </div>
        </div>
        </>
    );
}


