import { useState, useMemo, useContext, useEffect, useRef } from "react"
import { useNetworkingHooks } from "./NetworkMessaging";
import Context from "../context/Context";


export function useTeamSelectionData(props){
    const context = useContext(Context)
    const {SendSpecificMessageToServer} = useNetworkingHooks(context.user);
    const [myTeamInt, setMyTeam] = useState(-1);
    const [homeTeam, setHomeTeam] = useState({color: '#dd0000', name: 'Red'});
    const [awayTeam, setAwayTeam] = useState({color: '#0000dd', name: 'Blue'});
    const [neutralTeam, setNeutralTeam] = useState({color: '#7d7f7c', name: 'No Team'});
    const [teamless, setTeamless] = useState({color: 'black', name: 'Teamless'});
    const [isHomeTeamButtonDisabled, setHomeTeamButtonDisabledState] = useState(false);
    const [isAwayTeamButtonDisabled, setAwayTeamButtonDisabledState] = useState(false);
    const [numPlayersReady, setNumPlayersReady] = useState({home: 0, away:0});


    const myTeam = useMemo(() => {switch(myTeamInt){
        case -2: return teamless;
        case -1: return neutralTeam;
        case 0: return homeTeam;
        case 1: return awayTeam;
    }}, [myTeamInt, homeTeam, awayTeam])

    const otherTeam = useMemo(() => {switch(myTeamInt){
        case -2: return teamless;
        case -1: return neutralTeam;
        case 0: return awayTeam;
        case 1: return homeTeam;
    }}, [myTeamInt, homeTeam, awayTeam])

    const setHomeButtonDisableState = (state) => {
        setHomeTeamButtonDisabledState(state === `true`);
       
    }
    const setAwayButtonDisableState = (state) => {
        //console.log("Setting Away Team Button State to : ", state);
        setAwayTeamButtonDisabledState(state === `true`);

     
    }
    const SetTeam_Home = (team) => {
        //console.log(`setting my teamcontext team here home`, team);
        setHomeTeam(team);
    }

    const SetTeam_Away = (team) => {
        setAwayTeam(team);
    }

    const SelectTeamToPlay = (team) => {
        if(team === 'Home'){
            setMyTeam(0);
            SendSpecificMessageToServer("JoinHomeTeam"); 

        }else if(team === 'Away'){
            setMyTeam(1);
            SendSpecificMessageToServer("JoinAwayTeam"); 

        }else if(team === "Teamless")
        {
            setMyTeam(-2);
            SendSpecificMessageToServer("JoinGame_Teamless");
        }
        else
        {
            setMyTeam(-1);
            SendSpecificMessageToServer("JoinNeutralTeam");
        }
    }

    const RequestCurrentlySelectedTeam = () => {
        if(myTeam === homeTeam) SendSpecificMessageToServer("JoinHomeTeam");
        if(myTeam === awayTeam) SendSpecificMessageToServer("JoinAwayTeam"); 
        if(myTeam === neutralTeam) SendSpecificMessageToServer("JoinNeutralTeam");
        if(myTeam === teamless) SendSpecificMessageToServer("JoinGame_Teamless"); 
    }

    const PlayersReadyToPlay = (num) => {
        setNumPlayersReady(num);
    }


    return { 
        Teams: useMemo(() => {return {hometeam: homeTeam, awayteam: awayTeam, neutralteam: neutralTeam, myteam: myTeam, otherteam: otherTeam, ishomeTeamButtonDisabled: isHomeTeamButtonDisabled, isawayTeamButtonDisabled: isAwayTeamButtonDisabled, playersReady: numPlayersReady }}, [homeTeam, awayTeam, neutralTeam, myTeam, isHomeTeamButtonDisabled, isAwayTeamButtonDisabled, numPlayersReady]), 
        DisableState: useMemo(()=>{return{home: isHomeTeamButtonDisabled, away: isAwayTeamButtonDisabled}}, [isHomeTeamButtonDisabled, isAwayTeamButtonDisabled]),     
        SelectTeamToPlay, 
        SetTeam_Home, 
        SetTeam_Away, 
        RequestCurrentlySelectedTeam,
        setHomeButtonDisableState,
        setAwayButtonDisableState,
        PlayersReadyToPlay
    };
}

export function useGameState(){
    const context = useContext(Context)
    const {SendSpecificMessageToServer} = useNetworkingHooks(context.user);

    const [JoinedGame, setJoinedGame] = useState(false);
    const [TeamsSelected, setTeamsSelected] = useState(false);
    const [IsOnGameLandingPage, setIsOnGameLandingPage] = useState(true);
    const [IsOnTeamSelectPage, setIsOnTeamSelectPage] = useState(false);
    const [IsCoreGameMode, setIsCoreGameMode] = useState(true);
    const [IsPaused, setIsPaused] = useState(false);

    const [CanStart, setCanStart] = useState(false);

    const [PlayerTurn, setPlayerTurn] = useState('');
    const [MyPlayer, setMyPlayer] = useState('');

    const [IsItMyTurn, setIsItMyTurn] = useState(false);
    const [IsItGameOver, setIsItGameOver] = useState(false);

    const [GameEndData, setGameEndData] = useState({homeWon: null, winningScore: 0, losingScore:0});

    const [Disconnected, setDisconnected] = useState(false);

    const JoinedTeam = () => {
        setJoinedGame(true);
    }

    const SetTeamSelectionPage = (yes, isCore) => {
        setIsOnTeamSelectPage(yes);

        let core = isCore == 'true';
        setIsCoreGameMode(yes ? core : false);
    }

    const SetTeamsSelected = (yes) => {
        setTeamsSelected(yes);
    }

    const CanStartGame = (enable) => {
        setCanStart(enable);
    }

    const IsMyTurn = (yes) => {
        setIsItMyTurn(yes);
    }

    const IsGameOver = (yes) => {
        setIsItGameOver(yes);
    }

    const UpdateGameOverData = (data) =>{
        setGameEndData(data);
    }

    const SetIsPaused = (yes) => {
        setIsPaused(yes);     
    }

    const SetCurrentPlayerSelection = (player) => {       
        setMyPlayer(player);       
    }

    const SetCurrentPlayerAvatar = (user) => {
        //console.log("SET MY PLAYER INFO [][][]   " , user);
        context.set("playerIcon", user.playerIcon);
        context.set("playerColor", user.playerColor);
        context.set("playerName", user.playerName);

        sessionStorage.setItem('user', JSON.stringify(user));
    }

    const ResetGameState = () => {
        CanStartGame(false);
        SetTeamsSelected(false);
        IsMyTurn(false);
        IsGameOver(false);
        SetTeamSelectionPage(false, false);
        setIsOnGameLandingPage(true);
        setIsPaused('false');
    }

    const DisconnectedFromServer = () => {
        setIsOnGameLandingPage(true);
        setDisconnected(true);
    }


    return { 
        GameState: useMemo(() => {return {
            joinedGame: JoinedGame, 
            teamsSelected: TeamsSelected, 
            isOnTeamSelectPage: IsOnTeamSelectPage, 
            isOnGameLandingPage: IsOnGameLandingPage,
            disconnected: Disconnected,  
            playerTurn: PlayerTurn, 
            myPlayer: MyPlayer, 
            isCoreMode: IsCoreGameMode, 
            canStart: CanStart, 
            isPaused: IsPaused, 
            myTurn: IsItMyTurn, 
            gameOver: IsItGameOver}}, 
        [JoinedGame, TeamsSelected, IsOnTeamSelectPage, IsOnGameLandingPage, Disconnected, PlayerTurn, MyPlayer, IsCoreGameMode, CanStart, IsPaused, IsItMyTurn, IsItGameOver]),
        GameOverData: useMemo(() => {return GameEndData}, [GameEndData]), 
        JoinedTeam,
        SetTeamsSelected,
        setIsOnGameLandingPage,
        SetTeamSelectionPage,
        CanStartGame, 
        IsMyTurn, 
        IsGameOver,
        SetIsPaused,
        SetCurrentPlayerSelection,
        ResetGameState,
        SetCurrentPlayerAvatar,
        UpdateGameOverData,
        DisconnectedFromServer
    };

}