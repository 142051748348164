import React, {useEffect, useState, useMemo} from "react";
import './App.css';
import websocket from "./helpers/WebSocket";
import { BrowserRouter, Route, Routes,  Link } from "react-router-dom";
import { RotationWarning } from "./Components/GameplayPrefabs";
import GameContextProvider from './context/GameContextProvider';
import TeamsContextProvider from "./context/TeamsContextProvider";
import GameStateContextProvider from "./context/GameStateContextProvider";
import Hockey from "./gamecontrollers/hockeyController";
import Soccer from "./gamecontrollers/soccerController";
import PaperFootball from "./gamecontrollers/paperFootballController";
import SlotCar from "./gamecontrollers/SlotCarController";
import ShuffleBoard from "./gamecontrollers/shuffleBoardController";
import Sumo from "./gamecontrollers/sumoController_new";
import DerbyController from "./gamecontrollers/DerbyController";
import Golf from "./gamecontrollers/golfController";
import Lobby from "./gamecontrollers/Lobby";
import ChuckIt from "./gamecontrollers/ChuckItController";
import JoinScreen from "./gamecontrollers/JoinScreen";
import { ConnectionStatusInterval } from "./Components/ConnectionStatus";

export default function App() {
  //console.log("App.js things happen here");
  const [ori, setOri] = useState(null);
  const [ping, setPing] = useState(0);

  useEffect(() =>{

    //Resizing
    function handleResize(){
        var resize = {
            width: window.innerWidth,
            height: window.innerHeight 
        };

        setOri(resize);
    }

    if(ori === null) handleResize();

    window.addEventListener('resize', handleResize)


    return _ => {
        window.removeEventListener('resize', handleResize);
    }
}, []);

  const FixedScreen = {
    height: '100dvh',
    overflow: 'hidden',
    width: '100dvw',
    position: 'fixed',

    userSelect: 'none',
    WebkitUserSelect: 'none', 
    MozUserSelect: 'none', /* Firefox */
    MsUserSelect: 'none', /* Internet Explorer/Edge */
  }

  const isLandscape = useMemo(() => { 
          if(ori != null) return ori.width > ori.height; 
          else return false;
          }, [ori]
      );

  return (
    
    <div style={{...FixedScreen}}>
      
    <GameContextProvider>
      <TeamsContextProvider>
        <GameStateContextProvider>
          <ConnectionStatusInterval />
          <BrowserRouter>
          {isLandscape ? <RotationWarning glow={'pink'} /> : 
          <>
            <Routes>
              <Route path="/Play" element={<JoinScreen/>} exact/>
              <Route path="/Lobby" element={<Lobby/>} exact/>
              <Route path="/Hockey" element={<Hockey/>} exact/>
              <Route path="/Soccer" element={<Soccer/>} exact/>
              <Route path="/Sumo" element={<Sumo/>} exact/>
              <Route path="/PaperFootball" element={<PaperFootball/>} exact/>
              <Route path="/SlotCar" element={<SlotCar/>} exact/>
              <Route path="/ShuffleBoard" element={<ShuffleBoard/>} exact/>
              <Route path="/Golf" element={<Golf/>} exact/>
              <Route path="/MonsterTruck" element={<DerbyController/>} exact/>
              <Route path="/ChuckIt" element={<ChuckIt/>} exact/>
              <Route path="/" element={<JoinScreen exact/>} />
            </Routes>
          </>}
          </BrowserRouter>
          
        </GameStateContextProvider>
      </TeamsContextProvider>
    </GameContextProvider>
    </div>
  
  );  
}


/*
<BrowserRouter>
      <Routes>
        <Route path="/" component={LandingPageClass} exact/>
        <Route path="/CarRace" component={CarRace} exact/>
        </Routes>
    </BrowserRouter>
*/


/*
 <nav>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/user/:id">User</Link>
        </div>
      </nav>
*/

/*
        <Route path="/CarRace/:id" component={CarRace} />

*/


