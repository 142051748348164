import React, { Component } from "react";
import Context from './Context';
import { GenerateUniqueID } from "../helpers/GenerateUniquePlayeID";
import { GetPlayerIcon } from "../Sprites/PlayerIconLoader";

class GameContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: {
        playerId: '',
        playerName: 'Rookie',
        playerIcon: 'baseball',
        playerColor: 'charcoal',
        selectedPlayerPuck: 0,
        spinValue: 50,
        latencyTimerId: null
      }
    }
  }

  componentDidMount() {   
    this.LoadContextInformation();
  }

  LoadContextInformation = () =>{
    //Plyer ID
      let ID = this.playerId;
      if(localStorage.getItem('PlayerId')){
        this.set('playerId', localStorage.getItem('PlayerId'));

      }else{
        if(ID === undefined || ID === null || ID === ''){
          ID = GenerateUniqueID();
          this.set('playerId', ID);
          localStorage.setItem('PlayerId', ID);
        }
      }

      if(sessionStorage.getItem('user')){      
        let user = JSON.parse(sessionStorage.getItem('user')); 
        console.log(user);

        this.set('playerName', user.playerName);
        this.set('playerIcon', user.playerIcon);
        this.set('playerColor', user.playerColor);
      }

}

  changeValue = (value) => {
    this.setState({
      value
    })
  }

  set = (key, value) => {
    var context = this.state.context;
    context[key] = value;
    this.setState({
      context
    })
  }
  render() {
    const context = {
      user: this.state.context,
      set: this.set
    };

    return (
      <Context.Provider value={context}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default GameContextProvider;
