import websocket from "./WebSocket";

export function useNetworkingHooks(UserContext) {

    function SendSpecificMessageToServer(Id, Payload){
    
        var msg = {
            id: Id,
            payload: JSON.stringify(Payload ?? ''),
            userContext: UserContext
        }

        websocket.Send(msg);
    }

    const JoinWaitingRoom = () => {
        SendSpecificMessageToServer("RequestToConnectToRoom");
        
    }

    const Disconnect = () => {
        SendSpecificMessageToServer("DiscontinueConnection");
    }

    const Reconnect = (callback) =>{
        let savedJAX  = sessionStorage.getItem("sessionCode");

        if(savedJAX !== null && savedJAX !== undefined && savedJAX !== '')
            {
                if(savedJAX.length === 4 && savedJAX.matchAll(/[A-Z]/g) !== null)
                    {
                        SendSpecificMessageToServer(`phoneClientServerJoinRequest`, savedJAX);
                    }
            }         
    }
    
    const RequestGameInformationData = () =>{
        SendSpecificMessageToServer("requestGameStateData");

    }

    const PressButton = (button) => {
        SendSpecificMessageToServer('ButtonDown', button);
    } 
    
    const ReleaseButton = (button) => {
        SendSpecificMessageToServer('ButtonUp', button);
    }
    
    const SendAxis= (axis) => {
        SendSpecificMessageToServer("UpdateAxisPosition", axis);
    }

    const SendConnectionStatusPing = (ping) => {
        SendSpecificMessageToServer("PhoneConnectionStatusPing", ping);
    }

    const CheckReconnectMessages = (id, payload, senderContext, callback) =>{
        switch(id){
            case "phoneClientServerResponse":
                if(payload.sessionId !== null){
                    //console.log(`Session ID i recieved is the following ${payload.sessionId}`);
                   // websocket.sessionId = payload.sessionId;
                    //Then do Join Waiting Room. 
                    JoinWaitingRoom(senderContext);


                }else{
                    //Display error Message
                    alert("Error connecting, please check your code and try again");
                }
            default:
                break;
        }
    }


    return { SendSpecificMessageToServer, 
        JoinWaitingRoom, 
        Disconnect, 
        CheckReconnectMessages, 
        Reconnect, 
        RequestGameInformationData, 
        PressButton, 
        ReleaseButton, 
        SendAxis,
        SendConnectionStatusPing};
}


